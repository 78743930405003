import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { SocialLinks } from '../../types/SocialLinks';

import type { IFooterSocialLinks } from './SocialLinks.d';
import style from './style.scss';

/**
 * Footer Social Links
 *
 * Renders a list of existing site social media
 */
@Component({})
export class FooterSocialLinks extends BaseComponent<IFooterSocialLinks, unknown> {
  /**
   * List of social media links
   */
  @Prop({ type: Array, required: true })
  socialLinks: SocialLinks[];

  /**
   * A text representing Facebook label
   */
  @Prop({ type: [Object, String], default: '' })
  facebookLabel?: I18nMessage;

  /**
   * A text representing instagram label
   */
  @Prop({ type: [Object, String], default: '' })
  instagramLabel?: I18nMessage;

  /**
   * A text representing pinterest label
   */
  @Prop({ type: [Object, String], default: '' })
  pinterestLabel?: I18nMessage;

  /**
   * A text representing youtube label
   */
  @Prop({ type: [Object, String], default: '' })
  youtubeLabel?: I18nMessage;

  /**
   * A text representing twitter label
   */
  @Prop({ type: [Object, String], default: '' })
  twitterLabel?: I18nMessage;

  /**
   * Determines styling for social
   */
  @Prop({ type: Boolean, default: false })
  socialLinksSmall?: boolean;

  render() {
    return (
      <div
        class={{
          [style.socialWrapper]: true,
          [style.socialLinksSmall]: this.socialLinksSmall,
        }}>
        <ul class={style.socialBox}>
          {this.socialLinks.map((sLink) => {
            return (
              <li
                class={{
                  [style.facebook]: sLink.type === 'facebook',
                  [style.instagram]: sLink.type === 'instagram',
                  [style.pinterest]: sLink.type === 'pinterest',
                  [style.youtube]: sLink.type === 'youtube',
                  [style.twitter]: sLink.type === 'twitter',
                }}
                key={sLink.type}>
                <a
                  href={sLink.url}
                  aria-label={this.getTranslation(
                    sLink.type === 'facebook'
                      ? this.facebookLabel
                      : sLink.type === 'instagram'
                      ? this.instagramLabel
                      : sLink.type === 'twitter'
                      ? this.twitterLabel
                      : sLink.type === 'pinterest'
                      ? this.pinterestLabel
                      : this.youtubeLabel,
                  )}
                  rel='noopener'
                  target='_blank'
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
